<template>
  <div class="detail">
    <div class="card" v-for="(item, index) in formList" :key="index">
      <div class="item">
        <p class="tit">序号</p>
        <p class="msg">{{ index + 1 }}</p>
        <p
          class="right"
          @click="remove(item, index)"
        >
          删除
        </p>
      </div>
      <div class="item">
        <p class="tit">工种</p>
        <p class="msg">
          <input
            type="text"
            maxlength="50"
            v-model="item.workType"
            :onkeyup="(item.workType = item.workType.replace(/\s+/g, ''))"
            placeholder="点击输入工种"
          />
        </p>
      </div>
      <div class="item">
        <p class="tit">姓名</p>
        <p class="msg">
          <input
            type="text"
            maxlength="20"
            v-model="item.name"
            :onkeyup="(item.name = item.name.replace(/\s+/g, ''))"
            placeholder="点击输入姓名"
          />
        </p>
      </div>
      <div class="item">
        <p class="tit">身份证号</p>
        <p class="msg">
          <input
            type="text"
            v-model="item.idno"
            :onkeyup="(item.idno = item.idno.replace(/\s+/g, ''))"
            placeholder="点击输入身份证号"
            maxlength="18"
          />
        </p>
      </div>
    </div>
    <div class="footer">
      <p @click="add">+增加雇员</p>
      <p @click="employeeSave">保存</p>
    </div>
  </div>
</template>

<script>
import { employeeList, employeeSave, employeeRemove } from "../utils/api";
import { validIdCard } from "../utils/validate";
export default {
  components: {},
  data() {
    return {
      formList: [
        {
          workType: "", //工种
          name: "", //姓名
          idno: "", //身份证号
        },
      ],
      startTime: sessionStorage.getItem("startTime"),
      endTime: sessionStorage.getItem("endTime"),
    };
  },
  created() {
    document.title = "雇员录入";
    this.employeeList();
  },
  methods: {
    //保存
    employeeSave() {
      let isInsure = true,
        isValidCard = true,
        personArr = [],
        cardArr = [];
      this.formList.map((item, index) => {
        cardArr.push(item.idno);
        item.serialNumber = index + 1;
        if (item.workType == "" || item.name == "" || item.idno == "") {
          isInsure = false;
        }
        if (item.idno != "" && !validIdCard(item.idno)) {
          isValidCard = false;
          personArr.push(index + 1);
        }
      });
      let newCardArr = [...new Set(cardArr)];
      if (!isInsure) {
        this.$toast("雇员信息不完整，请补全信息再提交");
      } else if (!isValidCard) {
        this.$toast(`雇员序号为${personArr.toString()}身份证号码格式不正确`);
      } else if (cardArr.length != newCardArr.length) {
        this.$toast("雇员信息不能有重复，请确认后再提交");
      } else {
        let params = {
          qzEmployeeRequests: this.formList,
        };
        if (sessionStorage.getItem("orderId")) {
          params.orderId = sessionStorage.getItem("orderId");
        }
        params.startTime = this.startTime;
        params.endTime = this.endTime;
        this.$toast.loading({
          message: "加载中...",
          forbidClick: true,
          duration: 0,
        });
        employeeSave(params).then((res) => {
          sessionStorage.setItem("orderId", res.result);
          sessionStorage.setItem("employSum", this.formList.length);
          this.$router.go(-1);
          this.$toast.clear();
        });
      }
    },
    //雇员列表
    employeeList() {
      let params = {};
      if (sessionStorage.getItem("orderId")) {
        params.orderId = sessionStorage.getItem("orderId");
      }

      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });

      employeeList(params).then((res) => {
        console.log(res);
        if (res.result.length == 0) {
          this.formList = [
            {
              workType: "", //工种
              name: "", //姓名
              idno: "", //身份证号
            },
          ];
        } else {
          this.formList = res.result;
        }
        this.$toast.clear();
      });
    },
    //删除雇员
    remove(item, index) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确认删除该雇员？",
        })
        .then(() => {
          // on confirm
          if (item.id) {
            let params = {
              id: item.id,
            };
            this.$toast.loading({
              message: "加载中...",
              forbidClick: true,
              duration: 0,
            });
            employeeRemove(params).then((res) => {
              this.formList.map((item1, index1) => {
                if (item.id == item1.id) {
                  this.formList.splice(index1, 1);
                }
              });
              let params1 = {};
              if (sessionStorage.getItem("orderId")) {
                params1.orderId = sessionStorage.getItem("orderId");
              }

              employeeList(params1).then((res1) => {
                sessionStorage.setItem("employSum", res1.result.length);
                this.$toast.success("删除成功");
              });
            });
          } else {
            this.formList.map((item1, index1) => {
              if (index == index1) {
                this.formList.splice(index1, 1);
              }
            });
            let params1 = {};
            if (sessionStorage.getItem("orderId")) {
              params1.orderId = sessionStorage.getItem("orderId");
            }

            employeeList(params1).then((res1) => {
              sessionStorage.setItem("employSum", res1.result.length);
              this.$toast.success("删除成功");
            });
          }
        })
        .catch(() => {
          // on cancel
        });
    },
    //添加雇员
    add() {
      let obj = {
        workType: "", //工种
        name: "", //姓名
        idno: "", //身份证号
      };
      this.formList.push(obj);
    },
  },
};
</script>

<style lang="scss" scoped>
.detail {
  padding: 0 10px 100px;
  .card {
    padding: 0 20px;
    border-radius: 6px;
    border: 1px solid #f3f4ff;
    margin-top: 22px;
  }
  .item {
    padding: 16px 0;
    border-bottom: 1px solid #f3f4ff;
    display: flex;
    align-items: center;
    .tit {
      width: 70px;
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
    }
    .msg {
      width: 100%;
      flex: 1;
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
      margin-left: 20px;
      input {
        width: 100%;
      }
    }
    .right {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
    }
  }
  .footer {
    width: 100%;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 100;
    p {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    p:first-child {
      width: 124px;
      height: 45px;
      border-radius: 23px;
      border: 1px solid $primary-color;
      font-size: 14px;
      font-weight: bold;
      color: $primary-color;
      line-height: 20px;
    }
    p:last-child {
      width: 124px;
      height: 45px;
      border-radius: 23px;
      border: 1px solid $primary-color;
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;
      line-height: 20px;
      background: $primary-color;
    }
  }
}
</style>